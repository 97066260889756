// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("jquery")
require("@rails/activestorage").start()
require("channels")
require("trix")
require("chartkick")
require("chart.js")
require("select2/dist/css/select2")

import $ from 'jquery';


global.$ = $;
global.jQuery = $;
import "../channels";

import './htmx.js'; // We don't need `import "htmx.org"` anymore.
import "htmx.org/dist/ext/response-targets"
import './dashboards.js';
import 'popper.js'
import * as bootstrap from "bootstrap";
import "@popperjs/core";
import Chartkick from "chartkick";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ahoy from "ahoy.js";
import 'cocoon-js';

import './introduction.js';

import Choices from "choices.js";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'particles.js';


import DataTable from 'datatables.net-dt';

document.addEventListener("turbolinks:load", function() {
// Initialize AOS
    AOS.init();

});

document.addEventListener("turbolinks:load", function() {
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
});

document.addEventListener('turbolinks:load', function () {
    var tooltipElement = document.getElementById('tooltip-element');
    var tooltip = new bootstrap.Tooltip(tooltipElement);

});

document.addEventListener("turbolinks:load", function () {
    // If using a config file
    particlesJS("particles-js", {
        "particles": {
            "number": {"value": 40, "density": {"enable": false, "value_area": 800}},
            "color": {"value": "#004F6E"},
            "shape": {
                "type": "circle",
                "stroke": {"width": 1, "color": "#000000"},
                "polygon": {"nb_sides": 12},
                "image": {"src": "img/github.svg", "width": 100, "height": 100}
            },
            "opacity": {
                "value": 0.8,
                "random": false,
                "anim": {"enable": false, "speed": 1, "opacity_min": 0.1, "sync": false}
            },
            "size": {
                "value": 6,
                "random": true,
                "anim": {"enable": false, "speed": 20, "size_min": 0.1, "sync": false}
            },
            "line_linked": {
                "enable": true,
                "distance": 300,
                "color": "#004F6E",
                "opacity": 0.6,
                "width": 1
            },
            "move": {
                "enable": true,
                "speed": 1,
                "direction": "none",
                "random": true,
                "straight": false,
                "out_mode": "bounce",
                "bounce": false,
                "attract": {"enable": false, "rotateX": 2966.312312601217, "rotateY": 1763.753266952075}
            }
        },
        "interactivity": {
            "detect_on": "canvas",
            "events": {
                "onhover": {"enable": true, "mode": "repulse"},
                "onclick": {"enable": true, "mode": "push"},
                "resize": true
            },
            "modes": {
                "grab": {"distance": 400, "line_linked": {"opacity": 1}},
                "bubble": {"distance": 400, "size": 40, "duration": 2, "opacity": 8, "speed": 3},
                "repulse": {"distance": 200, "duration": 0.4},
                "push": {"particles_nb": 4},
                "remove": {"particles_nb": 2}
            }
        },
        "retina_detect": true
    });



});


$(document).on('hidden.bs.modal', '.my-select2-modal', function () {
    // Restore the original enforceFocus behavior when the modal is closed
    $.fn.modal.Constructor.prototype._enforceFocus = $.fn.modal.Constructor.prototype.enforceFocus;
});

// Register the plugin
Chart.plugins.register(ChartDataLabels);

// Set global defaults for datalabels
Chart.defaults.global.plugins.datalabels = {
    color: "#004F6E",
    offset: 5,
    align: "end",
    anchor: "center",
    font: {
        size: 12,
        weight: "bold"
    },
    formatter: function (value) {
        return value; // Display the actual value
    }
};

// Add Chartkick adapter
Chartkick.addAdapter(Chart);

// Set global options for Chartkick
Chartkick.options = {
    library: {
        animation: {
            easing: 'easeOutQuart', // Smooth animations
            duration: 1000          // Animation duration in milliseconds
        }
    }
};

ahoy.configure({
    urlPrefix: "",
    visitsUrl: "/ahoy/visits",
    eventsUrl: "/ahoy/events",
    page: null,
    platform: "Web",
    useBeacon: true,
    startOnReady: true,
    trackVisits: true,
    cookies: false,
    cookieDomain: null,
    headers: {},
    visitParams: {},
    withCredentials: false
});

ahoy.trackAll();

window.setTimeout(function () {
    $(".alert").fadeTo(1000, 0).slideUp(1000, function () {
        $(this).remove();
    });
}, 5000);


/*
document.addEventListener("turbolinks:load", () =>{

if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
    Chartkick.eachChart(function (chart) {
      chart.updateData(null)
      chart.setOptions({...chart.getOptions(), ...{library: {animation: false}}})
    })
  }

  });


*/
// Add this code to your JavaScript file


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

if (navigator.serviceWorker) {
    navigator.serviceWorker.register('/serviceworker.js', {scope: './'})
        .then(function (reg) {
            console.log('[Page] Service worker registered!');
        });
}
document.addEventListener("turbolinks:load", () => {

    $('#nav-tab a').on('click', function (e) {
        e.preventDefault()
        $(this).tab('show')
    })
});

//cookie consent
document.addEventListener('DOMContentLoaded', () => {
    const rejectAllBtn = document.getElementById('rejectAllCookies');
    const acceptSelectedBtn = document.getElementById('acceptSelectedCookies');

    // Direct Allow/Reject from the card
    const cookieAllowDirect = document.getElementById('cookieAllowDirect');
    const cookieRejectDirect = document.getElementById('cookieRejectDirect');

    if (rejectAllBtn && acceptSelectedBtn) {
        rejectAllBtn.addEventListener('click', () => {
            setCookiePreferences({analytics: false});
        });

        acceptSelectedBtn.addEventListener('click', () => {
            const analytics = document.getElementById('analyticsCookies').checked;
            setCookiePreferences({analytics: analytics});
        });
    }

    if (cookieAllowDirect) {
        cookieAllowDirect.addEventListener('click', () => {
            setCookiePreferences({analytics: true});
        });
    }

    if (cookieRejectDirect) {
        cookieRejectDirect.addEventListener('click', () => {
            setCookiePreferences({analytics: false});
        });
    }

    function setCookiePreferences(preferences) {
        const cookieValue = encodeURIComponent(JSON.stringify(preferences));
        document.cookie = `cookie_preferences=${cookieValue}; path=/; max-age=31536000; secure; SameSite=Lax`;
        location.reload();
    }
});


document.addEventListener("turbolinks:load", () => {


    $(".panel-heading").mouseenter(function () {
        $(".panel-collapse").collapse("show");
    });
    $(".panel-collapse").mouseleave(function () {
        $(".panel-collapse").collapse("hide");
    });

});


document.addEventListener("turbolinks:load", () => {


    $('#addresses').on('cocoon:before-insert', function (e, el_to_add) {
        return el_to_add.fadeIn(1000);
    });
    $('#addresses').on('cocoon:after-insert', function (e, added_el) {
        added_el.effect('highlight', {}, 500);
        return recount();
    });
    $('#addresses').on('cocoon:before-remove', function (e, el_to_remove) {
        $(this).data('remove-timeout', 1000);
        return el_to_remove.fadeOut(1000);
    });
    return $('#addresses').on('cocoon:after-remove', function (e, removed_el) {
        return recount();
    });
});

//checkboxes
document.addEventListener('turbolinks:load', function () {
    // Get the select all checkbox
    const selectAllCheckbox = document.getElementById('select-all');

    // Get all individual row checkboxes
    const checkboxes = document.querySelectorAll('.select-checkbox');

    // Add event listener to the select all checkbox
    selectAllCheckbox.addEventListener('change', function () {
        const isChecked = this.checked;

        // Set all checkboxes to the same state as the select all checkbox
        checkboxes.forEach(function (checkbox) {
            checkbox.checked = isChecked;
            toggleRowHighlight(checkbox);
            toggleIcon(checkbox);
        });
    });

    // Add event listener to each individual checkbox
    checkboxes.forEach(function (checkbox) {
        checkbox.addEventListener('change', function () {
            toggleRowHighlight(this);
            toggleIcon(this);

            // Optionally, uncheck the "Select All" checkbox if any single checkbox is unchecked
            if (!this.checked) {
                selectAllCheckbox.checked = false;
            }
            // Check "Select All" if all are checked
            else if (Array.from(checkboxes).every(chk => chk.checked)) {
                selectAllCheckbox.checked = true;
            }
        });

        // Add click event to the row containing the checkbox
        const row = checkbox.closest('.selectable-row');
        row.addEventListener('click', function (event) {
            // Avoid triggering when clicking directly on the checkbox
            if (event.target.type !== 'checkbox') {
                checkbox.checked = !checkbox.checked;
                toggleRowHighlight(checkbox);
                toggleIcon(checkbox);

                // Adjust the "Select All" checkbox state based on individual row selection
                if (!checkbox.checked) {
                    selectAllCheckbox.checked = false;
                } else if (Array.from(checkboxes).every(chk => chk.checked)) {
                    selectAllCheckbox.checked = true;
                }
            }
        });
    });

    // Function to toggle the row highlight based on the checkbox state
    function toggleRowHighlight(checkbox) {
        const row = checkbox.closest('.selectable-row');
        if (checkbox.checked) {
            row.classList.add('selected');
        } else {
            row.classList.remove('selected');
        }
    }

    // Function to toggle the icon between checked and plus
    function toggleIcon(checkbox) {
        const row = checkbox.closest('.selectable-row');
        const uncheckedIcon = row.querySelector('.icon-unchecked');
        const checkedIcon = row.querySelector('.icon-checked');
        if (checkbox.checked) {
            uncheckedIcon.style.display = 'none';
            checkedIcon.style.display = 'inline';
        } else {
            uncheckedIcon.style.display = 'inline';
            checkedIcon.style.display = 'none';
        }
    }
});

document.addEventListener('turbolinks:load', function () {
    // Handle checkbox change events
    document.addEventListener('change', function (event) {
        if (event.target.matches('.my-checkbox')) {
            const checkbox = event.target;
            const parent = checkbox.parentElement;

            const uncheckedIcon = parent.querySelector('.icon-unchecked');
            const checkedIcon = parent.querySelector('.icon-checked');

            if (checkbox.checked) {
                uncheckedIcon.style.display = 'none';
                checkedIcon.style.display = 'inline';
            } else {
                uncheckedIcon.style.display = 'inline';
                checkedIcon.style.display = 'none';
            }
        }
    });

    // Handle icon click events
    document.addEventListener('click', function (event) {
        const icon = event.target.closest('.icon-unchecked, .icon-checked');
        if (icon) {
            const parent = icon.closest('.checkbox-container');
            const checkbox = parent.querySelector('.my-checkbox');

            // Toggle the checkbox state
            checkbox.checked = !checkbox.checked;

            // Trigger the change event on the checkbox to update icons
            const changeEvent = new Event('change', {bubbles: true});
            checkbox.dispatchEvent(changeEvent);
        }
    });
});


document.addEventListener('turbolinks:load', function () {
    var loadingIndicator = document.getElementById('loading-spinner');
    if (loadingIndicator) {
        loadingIndicator.style.display = 'none';
    }

    // Event Listener for all forms
    var forms = document.querySelectorAll('form');
    forms.forEach(function (form) {
        form.addEventListener('submit', function () {
            // Show loading indicator
            if (loadingIndicator) {
                loadingIndicator.style.display = 'flex';
            }
        });
    });

    // Event Listener for links with the class 'load-trigger'
    var loadTriggerLinks = document.querySelectorAll('.load-trigger');
    loadTriggerLinks.forEach(function (link) {
        link.addEventListener('click', function () {
            // Show loading indicator
            if (loadingIndicator) {
                loadingIndicator.style.display = 'flex';
            }
        });
    });
});

document.addEventListener('turbolinks:request-start', function () {
    var loadingIndicator = document.getElementById('loading-spinner');
    if (loadingIndicator) {
        loadingIndicator.style.display = 'flex';
    }
});

document.addEventListener('turbolinks:before-render', function () {
    var loadingIndicator = document.getElementById('loading-spinner');
    if (loadingIndicator) {
        loadingIndicator.style.display = 'flex';
    }
});

document.addEventListener('turbolinks:visit', function () {
    var loadingIndicator = document.getElementById('loading-spinner');
    if (loadingIndicator) {
        loadingIndicator.style.display = 'flex';
    }
});

function initializeFlatpickr() {
    flatpickr(".selector", {
        locale: {
            firstDayOfWeek: 1
        },
        minDate: "today",
        defaultDate: true,
        altFormat: "j. F Y, H:i",
        enableTime: true,
        dateFormat: "d.m.Y H:i",
        time_24hr: true,
        minTime: "5:00",
        maxTime: "23:00",
        weekNumbers: true
    });
    flatpickr(".custom_selector", {
        locale: {
            firstDayOfWeek: 1
        },
        maxDate: "today",
        defaultDate: true,
        altFormat: "j. F Y, H:i",
        weekNumbers: true
    });
}

// Initialize on initial page load and Turbolinks navigations
document.addEventListener("turbolinks:load", initializeFlatpickr);
document.addEventListener('htmx:afterOnLoad', initializeFlatpickr);
document.addEventListener('htmx:afterSwap', initializeFlatpickr);
document.addEventListener('htmx:afterSettle', initializeFlatpickr);
document.addEventListener('htmx:load', initializeFlatpickr);


function initializechoicesselect() {
    // Apply Choices.js to select elements with the "choices-select" class
    const elements = document.querySelectorAll(".choices-select");
    elements.forEach((element) => {
        new Choices(element, {
            removeItemButton: true,
            searchEnabled: true.valueOf(),
            addChoices: true,
            addItems: true,
            itemSelectText: "",

        });
    });
}

document.addEventListener("turbolinks:load", initializechoicesselect);
document.addEventListener('htmx:afterOnLoad', initializechoicesselect);
document.addEventListener('htmx:afterSwap', initializechoicesselect);
document.addEventListener('htmx:afterSettle', initializechoicesselect);
document.addEventListener('htmx:load', initializechoicesselect);


document.addEventListener("turbolinks:load", function () {
    const toggleButton = document.getElementById("toggleSidebar");
    const sidebar = document.getElementById("sidebar");
    const content = document.getElementById("content");

    if (toggleButton) {
        toggleButton.addEventListener("click", function () {
            sidebar.classList.toggle("expanded");
            sidebar.classList.toggle("collapsed");
            content.classList.toggle("expanded-content");
            content.classList.toggle("collapsed-content");
        });
    }
});
document.addEventListener('turbolinks:load', function () {
    let loadMore = document.getElementById('load-more');

    // 1) Infinite-scroll logic
    window.addEventListener('scroll', function () {
        // If the #load-more button exists and we're within 100px of the bottom:
        if (loadMore && window.scrollY + window.innerHeight > document.documentElement.scrollHeight - 100) {
            loadMore.click(); // programmatically click the button to load more leads
        }
    });

    // 2) Show a spinner when the #load-more button is clicked
    document.addEventListener('click', function (event) {
        if (event.target && event.target.id === 'load-more') {
            event.target.innerHTML = '<i class="fa-solid fa-spinner fa-spin"></i> Loading...';
        }
    });
});


require("@rails/actiontext")
import "controllers"
