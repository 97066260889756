import { Controller } from "stimulus"
import Sortable from "sortablejs"

import Rails from "@rails/ujs"

export default class extends Controller {
	
  connect() {
  	

	//console.log(this.data.get("datenew"))
	//console.log(this.datedayTarget)
    
    this.sortable = 
    Sortable.create(
    	this.element, {
    	animation: 150,
    	ghostClass: 'bg-light-orange',
    	onEnd: this.end.bind(this)

    	
    })

  }


  end(event){
	
  	
  	let id = event.item.dataset.id

  	
  	console.log("Position: ", event.newIndex +1, "ID: ", id)
	console.log(event)


	let data = new FormData()
	//data.append("next_contact", event.to.id)
	data.append("position", event.newIndex)
	console.log(data)

	Rails.ajax({
		url: this.data.get("url").replace(":id", id),
		type:"PATCH",
		data: data,
		success: function(data) { },
		error: function(data) { alert("Fehler") }


	})
	}

}
