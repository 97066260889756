import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
    static targets = ["badge"]

    // No need for the connect method unless you have other setup tasks

    markAsRead(event) {
        event.preventDefault()
        const notificationItem = event.currentTarget
        const notificationId = notificationItem.dataset.notificationId
        const url = notificationItem.href // Get the URL from the href attribute


        Rails.ajax({
            url: `/notifications/${notificationId}/mark_as_read`,
            type: "POST",
            success: (data) => {
                // Update the unread count badge
                if (this.hasBadgeTarget) {
                    if (data.unread_count > 0) {
                        this.badgeTarget.textContent = data.unread_count
                        this.badgeTarget.style.display = ''
                    } else {
                        this.badgeTarget.textContent = 'Keine Benachrichtigungen'

                    }
                }

                // Remove the notification from the list
                notificationItem.remove()
                // After marking as read, navigate to the notification URL
                window.location.href = url
            }
        })
    }
}
