document.addEventListener('turbolinks:load', () => {
    const addWidgetButton = document.getElementById('add-widget-button');
    const widgetFormContainer = document.getElementById('widget-forms');

    if (addWidgetButton && widgetFormContainer) {
        addWidgetButton.addEventListener('click', () => {
            const newWidgetForm = widgetFormContainer.firstElementChild.cloneNode(true);
            widgetFormContainer.appendChild(newWidgetForm);
        });
    }
});