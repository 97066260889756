import { Controller } from "stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

export default class extends Controller {
	connect() {
		const statuses = [
			'pending', 'contacted', 'proposal_sent', 'negotiation',
			'pending_approval', 'converted', 'lost', 'on_hold', 'canceled'
		];

		statuses.forEach(status => {
			Sortable.create(this.element, {
				group: {
					name: status,
					put: statuses,
					pull: true
				},
				onEnd: this.end.bind(this)
			});
		});
	}

	end(event) {
		let id = event.item.dataset.id;
		let newStatus = event.to.id; // Capture the target column's ID as the new status

		let data = new FormData();
		data.append("status", newStatus); // Ensure the "status" parameter is being sent correctly

		Rails.ajax({
			url: this.data.get("url").replace(":id", id),
			type: "PATCH",
			data: data,
			success: () => {
				console.log("Status updated successfully"); // Add a console log for debugging
				 // Optionally, reload to show the updated status in the UI
			},
			error: (err) => console.error("Failed to update status", err) // Log any errors for troubleshooting
		});
	}
}
