import consumer from "./consumer"


consumer.subscriptions.create("NotificationsChannel", {


  connected() {
      console.log("WebSocket connected to NotificationsChannel");
  },

  disconnected() {
      console.log("WebSocket disconnected from NotificationsChannel");
  },

    received(data) {
        console.log("Data received from ActionCable: ", data);
        // Update the notifications list
        const notificationsList = document.getElementById("notifications-list")
        notificationsList.insertAdjacentHTML("afterbegin", data.notification_html)

        // Update the unread count badge
        const badge = document.getElementById("notification-badge")
        badge.textContent = data.unread_count

        if (data.unread_count > 0) {
            badge.style.display = ''


        } else {
            badge.textContent = 'Keine Benachrichtigungen'
            badge.style.display = 'none'
        }
    }
});

